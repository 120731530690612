





















































import ApiService from '@/common/api.service'
import { ProjectModel } from '@/common/classes/models'
import { endpoints } from '@/common/config'
import { processResponseError } from '@/common/misc'
import Vue from 'vue';

export default Vue.extend({
    name: 'adminProjectTab',
    data(){
        const states = Object.freeze({
            FETCHING_PROJECTS: 0,
            DEFAULT: 1,
            FETCHING_SWITCH: 2,
        })
        return{
            states,
            state: states.FETCHING_PROJECTS,
            allProjects: [] as ProjectModel[],
            searchName: '',
            searchAuthor: '',
            searchExemplary: false,
            searchAdminHidden: false,
        }
    },
    async mounted(){
        this.state = this.states.FETCHING_PROJECTS;
        try {
            const {data} = await ApiService.get(endpoints.adminGetAllProjects)
            this.allProjects = (data.projects as Record<string,any>[]).map((p)=>this.literalToProjectModel(p));
        } catch (e) {
            alert(processResponseError(e));
        } finally{
            this.state = this.states.DEFAULT;
        }
    },
    methods: {
        async switchFlagForProject(project:ProjectModel,flag:string){
            this.state = this.states.FETCHING_SWITCH;

            try {
                const projId = project.data.id;
                const hasFlag = project.data.flags.includes(flag);
                const endpoint = (()=>{
                    switch (flag) {
                        case ProjectModel.FLAGS.EXEMPLARY: return endpoints.adminPutExemplaryProject(projId, !hasFlag);
                        case ProjectModel.FLAGS.ADMIN_HIDDEN: return endpoints.adminPutAdminHiddenProject(projId, !hasFlag);
                        default: throw new Error("Unrecognized project flag: "+flag);
                    }
                })();
                const { data } = await ApiService.put(endpoint);
                const allProjIndex = this.allProjects.findIndex((p)=>p.data.id == projId);
                this.$set( this.allProjects, allProjIndex, this.literalToProjectModel(data.project));
            } catch (e) {
                console.error(e);
                alert(processResponseError(e));
            } finally {
                this.state = this.states.DEFAULT;
            }
        },
        checkProjectHasFlag(project:ProjectModel, flag:string){
            return project.data.flags.includes( flag );
        },
        literalToProjectModel(literal:Record<string,any>):ProjectModel{
            if(literal.data && literal.project && literal.channels) return literal as ProjectModel;
            const literalData:any = Object.assign({},literal);
            delete literalData.product;
            delete literalData.channels;
            return new ProjectModel(literal.product,literal.channels,literalData);
        },
    },
    computed: {
        projectFlags():Record<string,string>{
            return ProjectModel.FLAGS;
        },
        //Sorted and filtered projects
        displayProjects():ProjectModel[]{
            const namePredicate = (p:ProjectModel)=>p.data.name.toLowerCase().includes(this.searchName.toLowerCase());
            const authorPredicate = (p:ProjectModel)=>{
                let {username,email} = p.data.author as {username:string,email:string};
                username = username.toLowerCase();
                email = email.toLowerCase();
                const query = this.searchAuthor.toLowerCase();
                return username.includes(query)||email.includes(query);
            };
            const exemplaryPredicate = (p:ProjectModel)=>!this.searchExemplary||this.checkProjectHasFlag( p, ProjectModel.FLAGS.EXEMPLARY );
            const adminHiddenPredicate = (p:ProjectModel)=>!this.searchAdminHidden||this.checkProjectHasFlag( p, ProjectModel.FLAGS.ADMIN_HIDDEN );
            const predicates = [
                namePredicate,
                authorPredicate,
                exemplaryPredicate,
                adminHiddenPredicate,
            ];

            let allProjects = this.allProjects;
            predicates.forEach((pred)=>allProjects = allProjects.filter(pred));

            return allProjects;
        },
        showFetchOverlay():boolean{
            return this.state != this.states.DEFAULT;
        },
    },
})
