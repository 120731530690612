import { render, staticRenderFns } from "./AdminProjectsTab.vue?vue&type=template&id=84dc0386&scoped=true&"
import script from "./AdminProjectsTab.vue?vue&type=script&lang=ts&"
export * from "./AdminProjectsTab.vue?vue&type=script&lang=ts&"
import style0 from "./AdminProjectsTab.vue?vue&type=style&index=0&id=84dc0386&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84dc0386",
  null
  
)

export default component.exports